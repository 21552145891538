import React, {useEffect, useState} from 'react';
import humanizeDuration from 'humanize-duration';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    fetch('https://validate.eosnation.io/wax/reports/endpoints.json')
    .then(res => res.json())
    .then(data => {
      let nodeData = data?.report?.atomic_https;
      nodeData.unshift([
        {
          "html_name": "AtomicAssets",
          "name": "atomicassets",
          "rank": 0
        },
        "https://wax.api.atomicassets.io",
        "",
        ""
      ]);
      setNodes(nodeData);
      setLoading(false);
    });
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden mx-4">

      <header className="container mx-auto py-4">
        <h1 className="text-4xl font-medium leading-tight">WAX Atomic Health</h1>
        <div className="text-sm leading-tight">
          Health check for WAX Atomic Assets API endpoints.
        </div>
        <div className="text-sm">
          Endpoint list obtained from <a href="https://validate.eosnation.io/wax/reports/endpoints.html" className="font-semibold">https://validate.eosnation.io/wax/reports/endpoints.html</a>
        </div>
      </header>

      <main className="container mx-auto flex-grow">

        {loading ?
          <Loading />
        :
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {nodes.map((node, i) => {
              return <Node node={node} key={i} />
            })}
          </div>
        }

      </main>

      <footer className="container mx-auto text-sm pt-4 bottom-0">
        <div className="">
          <div className="py-5 flex justify-between items-end">
            <div>

            </div>
            <div className="text-center font-semibold text-sm">
              Provided by <a href="https://blocdraig.wales"><img src="/images/blocdraig.png" className="inline max-h-9" alt="Bloc Draig"/></a>
            </div>
            <div>

            </div>
          </div>
        </div>
      </footer>

    </div>
  );
}

const Node = ({node}) => {
  const [loading, setLoading] = useState(true);
  const [api, setApi] = useState(null);
  const [behind, setBehind] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(node[1] + '/health')
      .then(res => res.json())
      .then(async data => {
        if (data?.success !== true) {
          setError("Unable to load API");
        } else {
          setApi(data);
          setBehind(Math.max(0, parseInt(data.data.chain.head_block) - parseInt(data.data.postgres.readers[0].block_num)));
          if (
            String(data.data.chain.status).toLowerCase() !== "ok"
          ) {
            setError("Chain Error");
            return;
          }
          if (
            String(data.data.postgres.status).toLowerCase() !== "ok"
          ) {
            setError("Postgres Error");
            return;
          }
          if (
            String(data.data.redis.status).toLowerCase() !== "ok"
          ) {
            setError("Redis Error");
          }
        }
      })
      .catch(() => {
        setError("Unable to load API");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [node]);

  return (
    <div className={"py-3 px-4 bg-slate-50 rounded-lg border border-gray-200 shadow" + (behind >= 120 && behind < 3600 ? " border-yellow-300" : "") + (behind >= 3600 ? " border-red-300" : "") + (error !== null ? " border-red-300" : "")}>
      {!loading && error !== "Unable to load API" && api &&
        <span className="float-right text-xs inline-block py-1 px-1.5 -mr-1 leading-none text-center whitespace-nowrap align-baseline font-medium bg-gray-800 text-white rounded">
          v{api.data.version}
        </span>
      }
      <h5 className="leading-none text-2xl font-bold tracking-tight text-gray-900" dangerouslySetInnerHTML={{__html:node[0].html_name}}>
      </h5>
      <p className="mb-3 text-xs font-normal text-gray-700">
        {node[1]}
      </p>
      {loading ?
        <Loading />
      :
        <>
          {error === "Unable to load API" || api == null ?
            <div className="text-sm">
              <div className={"flex p-2 mb-2 text-sm rounded-lg text-red-700 bg-red-100"} role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
                <div className="font-medium">
                  {error}
                </div>
              </div>
            </div>
          :
            <div className="text-sm">
              <div className="mb-2 grid grid-cols-3 gap-2">
                <Feature node={node} feature={"atomicassets"} />
                <Feature node={node} feature={"atomicmarket"} />
                <Feature node={node} feature={"atomictools"} />
              </div>
              <div className="mb-2 grid grid-cols-3 gap-2">
                <div
                  className={`text-center border border-gray-200 rounded${String(api.data.chain.status).toLowerCase() !== "ok" ? " border-red-300 text-red-700 bg-red-100" : ""}`}>
                  <div
                    className={`border-b py-0.5 ${String(api.data.chain.status).toLowerCase() !== "ok" ? "border-red-300 text-red-700 bg-red-100" : "bg-gray-100"}`}>
                    Chain
                  </div>
                  <div className="text-xs font-bold py-0.5">{api.data.chain.status}</div>
                </div>
                <div
                  className={`text-center border border-gray-200 rounded${String(api.data.postgres.status).toLowerCase() !== "ok" ? " border-red-300 text-red-700 bg-red-100" : ""}`}>
                  <div
                    className={`border-b py-0.5 ${String(api.data.postgres.status).toLowerCase() !== "ok" ? "border-red-300 text-red-700 bg-red-100" : "bg-gray-100"}`}>
                    Postgres
                  </div>
                  <div className="text-xs font-bold py-0.5">{api.data.postgres.status}</div>
                </div>
                <div className={`text-center border border-gray-200 rounded${String(api.data.redis.status).toLowerCase() !== "ok" ? " border-red-300 text-red-700 bg-red-100" : ""}`}>
                  <div className={`border-b py-0.5 ${String(api.data.redis.status).toLowerCase() !== "ok" ? "border-red-300 text-red-700 bg-red-100" : "bg-gray-100"}`}>
                    Redis
                  </div>
                  <div className="text-xs font-bold py-0.5">{api.data.redis.status}</div>
                </div>
              </div>
              <div className="px-3 py-2 text-sm text-gray-700 bg-gray-100 rounded-lg text-center">
                <span className="font-medium">{behind}</span> blocks behind
              </div>
              {behind > 120 &&
                <div
                  className={"flex p-2 mt-2 text-sm rounded-lg" + (behind >= 120 && behind < 3600 ? " text-yellow-700 bg-yellow-100" : "") + (behind >= 3600 ? " text-red-700 bg-red-100" : "")} role="alert">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                  </svg>
                  <div className="font-medium">
                    {humanizeDuration((behind / 2) * 1000)} behind
                  </div>
                </div>
              }
            </div>
          }
        </>
      }
    </div>
  );
}

const Feature = ({node, feature}) => {
  const [loading, setLoading] = useState(true);
  const [api, setApi] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const timeout = {
      atomicassets: 2000,
      atomicmarket: 4000,
      atomictools: 6000
    };

    setTimeout(() => {
      fetch(node[1] + '/' + feature + '/v1/config')
        .then(async res => {
          let data = await res.json();
          if (res.status === 429) {
            setError("Rate Limit");
          } else if (res.status === 404) {
            setError("Not Enabled");
          } else if (data?.success !== true) {
            setError("Unable to load API");
          } else {
            setApi(data);
          }
        })
        .catch(() => {
          setError("Unable to load API");
        })
        .finally(() => {
          setLoading(false);
        });
    }, timeout[feature]);
  }, [node, feature]);

  return (
    <div className="text-center border border-gray-200 rounded">
      <div className="border-b py-0.5 bg-gray-100">{feature}</div>
      <div className="text-xs font-bold py-0.5">
        {loading ?
          <Loading size={'small'} />
        :
          <>
            {error || api == null ?
              <>{error}</>
            :
              <>v{api.data.version}</>
            }
          </>
        }
      </div>
    </div>
  );
}

const Loading = ({size}) => {
  let classes = "w-10 h-10 border-[6px]";

  if (size === 'small') {
    classes = "w-4 h-4 border-[2px]";
  }

  return (
    <div className="justify-center items-center">
      <div style={{borderTopColor:"transparent"}} className={classes + " mx-auto border-gray-400 border-solid rounded-full animate-spin"}></div>
    </div>
  );
}

export default App;
